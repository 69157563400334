import React, { Suspense, lazy, useState } from "react";
// import Header from "../components/Layout/Header";
// import Hero from "../components/Route/Hero/Hero";

// import BestSellerBanner from "../components/bestSeller/BestSellerBanner";
// import BestSeller from "../components/bestSeller/BestSeller";
import Loader from "../components/Layout/Loader";
import useSWR from "swr";

// import Loader from "./components/Layout/Loader";
const Header = lazy(() => import("../components/Layout/Header"));
const FashionTab = lazy(() => import("../components/tabsHome/FashionTab"));
const ARmain = lazy(() => import("../components/AR/ARmain"));
const DesignerMain = lazy(() => import("../components/Designer/DesignerMain"));
const Reason = lazy(() => import("../components/reason/Reason"));
const MemoryWallMain = lazy(() =>
  import("../components/memoryWall/MemoryWallMain")
);
const HelpBanner = lazy(() => import("../components/Banners/HelpBanner"));
const FlashSale = lazy(() => import("../components/FlashSale/FlashSale"));
const FashionMain = lazy(() => import("../components/Fashion/FashionMain"));
const Premium = lazy(() => import("../components/premium/Premium"));
const TwoBanners = lazy(() => import("../components/Banners/TwoBanners"));
const InspireMe = lazy(() => import("../components/InspireMe/InspireMe"));
const NewInHomePage = lazy(() => import("../components/NewIn/NewInHomePage"));
const Hero = lazy(() => import("../components/Route/Hero/Hero"));
const ProductSwiperOne = lazy(() =>
  import("../components/Products/ProductSwiperOne")
);
const BestSellerBanner = lazy(() =>
  import("../components/bestSeller/BestSellerBanner")
);
const Footer = lazy(() => import("../components/Layout/Footer"));
const Categories = lazy(() =>
  import("../components/Route/Categories/Categories")
);
const BestSeller = lazy(() => import("../components/bestSeller/BestSeller"));

const HomePage = () => {
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDlawer = () => setOpen(false);

  //  display jokes from this api https://v2.jokeapi.dev/categories using swr
  const { data, error } = useSWR(
    "https://v2.jokeapi.dev/joke/Programming,Misc?format=xml&blacklistFlags=nsfw,sexist&type=single&lang=ru&amount=2"
  );

  console.log("data", data, "error", error);

  return (
    <div>
      <Header activeHeading={1} openDrawer={openDrawer} />

      {/* <NevBar /> */}
      <Suspense fallback={<Loader />}>
        <Hero />
        <Categories />

        <BestSellerBanner />
        <BestSeller />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ProductSwiperOne />
        <NewInHomePage />
        <DesignerMain />
        <ARmain />
        <InspireMe />
        <TwoBanners />
        <Premium />
        <FashionTab />
        <FlashSale />
        <HelpBanner />
        <MemoryWallMain />
        <Reason />
        <Footer />
      </Suspense>

      {/* <Events /> */}
      {/* <FeaturedProduct /> */}
      {/* <Sponsored /> */}
    </div>
  );
};

export default HomePage;
