// export const server = "/api/v2";
// local
// export const server = "http://localhost:8000/api/v2";
// export const backend_url = "http://localhost:8000/";
// production
export const server = "https://eyedictivee.com/api/v2";

export const backend_url = "https://eyedictivee.com/";
//
// export const backend_url = "http://52.91.194.75/";

// export const server = "/api/v2";

// export const backend_url = "http://54.145.134.182/";
// hii
// export const server = "/api/v2";
// export const backend_url = "http://18.206.156.62/";
// hii
// export const server = "http://65.1.204.29/api/v2";
// export const server = "https://server.eyedictivee.com/api/v2";

// export const backend_url = "https://server.eyedictivee.com/";
// export const backend_url = "https://server.eyedictivee.com/";
