import HomePage from "../pages/HomePage";

import { lazy } from "react";

const LoginPage = lazy(() => import("../pages/Login"));
const SignupPage = lazy(() => import("../pages/SignupPage"));
const ActivationPage = lazy(() => import("../pages/ActivationPage"));
const ProductsPage = lazy(() => import("../pages/ProductsPage"));
const BestSellingPage = lazy(() => import("../pages/BestSellingPage"));
const EventsPage = lazy(() => import("../pages/EventsPage"));
const FAQPage = lazy(() => import("../pages/FAQPage"));
const CheckoutPage = lazy(() => import("../pages/CheckoutPage"));
const PaymentPage = lazy(() => import("../pages/PaymentPage"));
const OrderSuccessPage = lazy(() => import("../pages/OrderSuccessPage"));
const ProductDetailsPage = lazy(() => import("../pages/ProductDetailsPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const ShopCreatePage = lazy(() => import("../pages/ShopCreate"));
const SellerActivationPage = lazy(() =>
  import("../pages/SellerActivationPage")
);
const ShopLoginPage = lazy(() => import("../pages/ShopLoginPage"));

const OrderDetailsPage = lazy(() => import("../pages/OrderDetailsPage"));
const TrackOrderPage = lazy(() => import("../pages/TrackOrderPage"));
const UserInbox = lazy(() => import("../pages/UserInbox.jsx"));
const PrescriptionPage = lazy(() => import("../pages/PrescriptionPage"));
const CartPage = lazy(() => import("../pages/CartPage"));
const GuidesBlogPage = lazy(() => import("../pages/GuidesBlogPage"));
const ProductsPageFresh = lazy(() => import("../pages/ProductsPageFresh"));
const OpaProfile = lazy(() => import("../pages/OpaProfile"));
const OpaLogin = lazy(() => import("../pages/OpaLogin"));
const AboutPage = lazy(() => import("../pages/AboutPage"));
// import OpaLogin from "../pages/opa/OpaLogin";

export {
  LoginPage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  ProductsPageFresh,
  BestSellingPage,
  EventsPage,
  FAQPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProductDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
  PrescriptionPage,
  CartPage,
  GuidesBlogPage,
  OpaProfile,
  OpaLogin,
  AboutPage,
};
