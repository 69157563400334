import { lazy } from "react";

const AdminDashboardWithdraw = lazy(() =>
  import("../pages/AdminDashboardWithdraw")
);
const AdminDashboardEvents = lazy(() =>
  import("../pages/AdminDashboardEvents")
);
const AdminDashboardProducts = lazy(() =>
  import("../pages/AdminDashboardProducts")
);
const AdminDashboardOrders = lazy(() =>
  import("../pages/AdminDashboardOrders")
);
const AdminDashboardSellers = lazy(() =>
  import("../pages/AdminDashboardSellers")
);
const AdminDashboardUsers = lazy(() => import("../pages/AdminDashboardUsers"));
const AdminDashboardPage = lazy(() => import("../pages/AdminDashboardPage"));

export {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw,
};
