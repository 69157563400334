import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  cart: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
};

// export const cartReducer = createReducer(initialState, {
//   addToCart: (state, action) => {
//     const item = action.payload;
//     const isItemExist = state.cart.find((i) => i._id === item._id);
//     if (isItemExist) {
//       return {
//         ...state,
//         cart: state.cart.map((i) => (i._id === isItemExist._id ? item : i)),
//       };
//     } else {
//       return {
//         ...state,
//         cart: [...state.cart, item],
//       };
//     }
//   },

//   removeFromCart: (state, action) => {
//     return {
//       ...state,
//       cart: state.cart.filter((i) => i._id !== action.payload),
//     };
//   },
// });

export const cartReducer = createReducer(initialState, {
  addToCart: (state, action) => {
    const item = action.payload;
    const isItemExist = state.cart.find(
      (i) =>
        i.frame._id === item.frame._id &&
        i.color === item.color &&
        i.size.size === item.size.size &&
        i.prescriptionType._id === item.prescriptionType._id // ... add other attributes as needed
    );

    if (isItemExist) {
      return {
        ...state,
        cart: state.cart.map((i) =>
          i.frame._id === isItemExist.frame._id &&
          i.color === item.color &&
          i.size.size === item.size.size &&
          i.prescriptionType._id === item.prescriptionType._id
            ? // ... add other attributes as needed
              item
            : i
        ),
      };
    } else {
      return {
        ...state,
        cart: [...state.cart, item],
      };
    }
  },

  removeFromCart: (state, action) => {
    return {
      ...state,
      cart: state.cart.filter((i) => i.frame._id !== action.payload), // Assuming you're passing just the _id to remove
    };
  },
});
