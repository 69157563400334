// // add to cart
// export const addTocart = (data) => async (dispatch, getState) => {
//   dispatch({
//     type: "addToCart",
//     payload: data,
//   });

//   localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
//   return data;
// };

// // remove from cart
// export const removeFromCart = (data) => async (dispatch, getState) => {
//   dispatch({
//     type: "removeFromCart",
//     payload: data._id,
//   });
//   localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
//   return data;
// };

// add to cart
export const addTocart = (cartData) => async (dispatch, getState) => {
  dispatch({
    type: "addToCart",
    payload: cartData,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return cartData;
};

// remove from cart
export const removeFromCart = (cartData) => async (dispatch, getState) => {
  dispatch({
    type: "removeFromCart",
    payload: cartData.frame._id, // Extracting _id from frame object
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return cartData;
};
